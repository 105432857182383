import React, { useState, useEffect, useContext } from 'react';
import { CustomerContext } from "../common/CustomerContext.js";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton } from '@mui/material';
import { Card, CardHeader } from "reactstrap";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditUserModal from './modals/EditUserModal';
import { palette } from '../assets/Palette';
import { Oval } from 'react-loader-spinner';
import { createTheme,  ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import i18next from 'i18next';
import { getCookie } from '../common/helpers/Common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Logout from '../components/Logout'
import {getPageHeading} from '../common/helpers/Common'


const UserManagementComponent = ({ ...props }) => {
  
  const theme = createTheme({palette: palette});
  const customerContext = useContext(CustomerContext);
  const [userRights, setUserRights] = useState([]);
  const [users, setUsers] = useState(null);
  const [roleRights, setRoleRights] = useState(null);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [lang, setLang] = useState(JSON.parse(localStorage.getItem('currentSettings') || '{}')?.[process.env.REACT_APP_SOURCE]?.language || 'en');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = getPageHeading() + " - User Management"
    const rights = getCookie('user_rights')
    // const rights = 'write:listing:usermanagement' // for local development
    if(rights.includes(':')){
      setUserRights(rights.split(':'))
    }
  }, []); // Runs on mount

  useEffect(() => {
    document.body.classList.add("white-content");
    if(userRights.includes('usermanagement')){
      loadUserData();
    }
    return () => {
      document.body.classList.remove('white-content');
    };
  }, [userRights]);

  const loadUserData = async () => {
      setSelectedUser(null)
      const roleRights = await getRoleRights()
      setRoleRights(roleRights)
      const usersData = await getUsers();
      setUsers(usersData);
      setIsLoading(false)
  }

  const {t, i18n} = useTranslation();

  const changeLanguage = (lang) => {
    setLang(lang)
    i18next.changeLanguage(lang);
  }

  const languageOptions = () => {
    const options = [{ name: 'Deutsch', code: 'de' }, { name: 'English', code: 'en' }, { name: 'French', code: 'fr' }]
    return options.map((dt, i) => {
      return (<MenuItem
        label="Select a langauge"
        value={dt.code}
        key={i} name={dt.name}>{dt.name}
      </MenuItem>
      )
    });
  }

  const getRoleRights = async () => {
    try {
      const res = await customerContext.userManagementApiService.getRoleRights()
      return res.json();
    } catch (error) {
      console.error('Error fetching user rights:', error);
      return null;
    }
  }

  const getUsers = async () => {
    try {
      const res = await customerContext.userManagementApiService.getUsers();
      return res.json();
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  };

  const toggleEditUserModal = () => {
    setEditUserModalOpen(!editUserModalOpen)
  }

  const closeEditUserModal = () => {
    setIsLoading(true)
    setSelectedUser(null)
    setEditUserModalOpen(false)
    loadUserData()
  }

  const onSubmit = () => {
    setIsLoading(true)
    setSelectedUser(null)
    setEditUserModalOpen(false)
    loadUserData()
  }

  const handleEditClick = (name) => {
    
    let user = {name: name, ...users[name]}
    setSelectedUser(user)
    setEditUserModalOpen(true);
  }

  const addUser = () => {
    setEditUserModalOpen(true)
  }

  const renderHeader = () => {
    return(
    <>
    <Card style={{paddingBottom: '20px'}}>
          <CardHeader>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.6%', justifyContent: 'space-between'}}>
              <Grid id='header' container direction="row" padding='0.6%'>
                <Grid item>
                  <a href='/'><img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" height='78' /></a>
                </Grid>
                <Grid item direction="column">
                  <div justifyContent="flex-start" style={{paddingLeft: '30px', paddingTop: '12px', paddingBottom: '10px'}}> <span style={{fontSize: 30, fontWeight: 'bold', whiteSpace: 'nowrap', color: palette.text.light}}>{i18n.t('userManagement.usermanagement')}</span></div>
                  {process.env.REACT_APP_MODE === 'test' && <div justifyContent="flex-start" style={{paddingLeft: '30px', paddingTop: '12px', paddingBottom: '10px'}}> <span style={{fontSize: 30, fontWeight: 'bold', whiteSpace: 'nowrap', color: palette.text.light}}>{i18n.t('dashboard.testEnvironment')}</span></div>}
                </Grid>
              </Grid>
              <Grid id='header-actions' container direction="column" alignItems="flex-end" justifyContent="space-between" padding='0.6%'>
                <Button color='primary' variant="contained" startIcon={<ExitToAppIcon />} style={{ outline: 'none' }}>
                <Link to='/' style={{color: 'white', textDecoration: 'none', fontWeight: 400}}>{i18n.t('userManagement.toProductsView')}</Link></Button>
                {customerContext.isBasicAuthEnabled && <Logout />}
                <Select MenuProps={ {getContentAnchorEl: null,anchorOrigin: {vertical: "bottom",horizontal: "left",}}} value={lang} onChange={(e) => changeLanguage(e.target.value)}>{languageOptions()}</Select>
              </Grid>
            </div>
          </CardHeader>
        </Card>
      <Card></Card>
      </>
    );
  }

  if (!userRights.includes('usermanagement')) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {renderHeader()}
          <br/>
          <div style={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
            <h1>{i18n.t('userManagement.onlySuperUsersAllowed')}</h1>
          </div>
          </ThemeProvider>
        </StyledEngineProvider>);
  } else if (isLoading) {
    return (
        <Grid
        container
        justifyContent="center"  
        alignItems="center"      
        style={{ height: '100vh' }}
        >
        <Grid item>
            <div><Oval type="TailSpin" color={palette.secondary.light} height={100} width={100} customerContext={customerContext}/></div>
        </Grid>
      </Grid>
    );
  }

  return (
    <div style={{padding: '1%', maxWidth: '1024px', margin: '0 auto'}}>
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
        {editUserModalOpen && <EditUserModal onClose={closeEditUserModal} user={selectedUser} roleRights={roleRights} customerContext={customerContext} toggleEditUserModal={toggleEditUserModal} onSubmit={onSubmit}/>}
        <Card style={{paddingBottom: '20px'}}>
          <CardHeader>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.6%', justifyContent: 'space-between'}}>
              <Grid id='header' container direction="row" padding='0.6%'>
                <Grid item>
                  <a href='/'><img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" height='78' /></a>
                </Grid>
                <Grid item direction="column">
                  <div justifyContent="flex-start" style={{paddingLeft: '30px', paddingTop: '12px', paddingBottom: '10px'}}> <span style={{fontSize: 30, fontWeight: 'bold', whiteSpace: 'nowrap', color: palette.text.light}}>{i18n.t('userManagement.userManagement')}</span></div>
                  {process.env.REACT_APP_MODE === 'test' && <div justifyContent="flex-start" style={{paddingLeft: '30px', paddingTop: '12px', paddingBottom: '10px'}}> <span style={{fontSize: 30, fontWeight: 'bold', whiteSpace: 'nowrap', color: palette.text.light}}>{i18n.t('dashboard.testEnvironment')}</span></div>}
                </Grid>
              </Grid>
              <Grid id='header-actions' container direction="column" alignItems="flex-end" justifyContent="space-between" padding='0.6%'>
                <Button color='primary' variant="contained" startIcon={<ExitToAppIcon />} style={{ outline: 'none' }}>
                <Link to='/' style={{color: 'white', textDecoration: 'none', fontWeight: 400}}>{i18n.t('userManagement.toProductsView')}</Link></Button>
                {customerContext.isBasicAuthEnabled && <Logout />}
                <Select MenuProps={ {getContentAnchorEl: null,anchorOrigin: {vertical: "bottom",horizontal: "left",}}} value={lang} onChange={(e) => changeLanguage(e.target.value)}>{languageOptions()}</Select>
              </Grid>
            </div>
          </CardHeader>
        </Card>
      <Card>
      <TableContainer>
        <Table className="users_table" >
          <TableHead>
            <TableRow >
              <TableCell style={{fontWeight: 'bold'}} >{i18n.t('userManagement.name')}</TableCell>
              <TableCell style={{fontWeight: 'bold'}} >{i18n.t('userManagement.role')}</TableCell>
              <TableCell style={{fontWeight: 'bold'}} >{i18n.t('userManagement.rights')}</TableCell>
              <TableCell style={{display: 'flex', justifyContent: 'center'}}>
                <Tooltip title={i18n.t('userManagement.createNew')}><AddBoxIcon
                key={'addUser'}
                style={{ color: palette.text.dark}}
                onClick={ () => addUser() }
              /></Tooltip></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Object.entries(users).map(([name, user]) => (
              <TableRow key={name}>
                <TableCell style={{ color: palette.text.ui_text }}>{name}</TableCell>
                <TableCell style={{ color: palette.text.ui_text }}>{i18n.t('userManagement.'+user.role)}</TableCell>
                <TableCell>
                <Table className="users_rights_table">
                  <TableBody>
                  {user.rights.map( (right, i) => (
                  <TableRow key={i}>
                  <Tooltip title={right.description}><TableCell style={{ border: 'none', color: palette.text.ui_text, padding: '0px' }}>{i18n.t('userManagement.'+right.right)}</TableCell></Tooltip>
                  </TableRow>
                ))}
                </TableBody>
                </Table>
                </TableCell>
                <TableCell>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Tooltip title={i18n.t('userManagement.edit')}>
                  <IconButton
                    key={"edit"}
                    style={{outline: 'none', backgroundColor: 'transparent'}}
                    onClick={ () => handleEditClick(name)}
                  ><MoreVertIcon/></IconButton>
                  </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Card>
      </ThemeProvider>
    </StyledEngineProvider>
    </div>
  );
};

export default UserManagementComponent;
